/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { List } from 'immutable';
import { Credential } from '@nettoken/models';
import Validator from '@nettoken/validator';
import { CredentialFilter, TYPE_CSV, extractLoginUrl } from 'main/import/common';
import { detectLoginInformationType } from 'main/user';

/**
 * Check if the file contents could be potentially parsed with a generic
 * algorithm.
 *
 * @param {object} file See `openExportedArchiveFile()` for signature.
 *
 * @returns {boolean}
 */
const isSupportedFile = file => {
  const { data, headers, type } = file;
  return type === TYPE_CSV && Array.isArray(data) && headers;
};

/**
 * @param {array} rows Exported CSV rows.
 * @param {Map} credentialsDomainMap Existing credentials sorted by domain.
 *
 * @returns {object} See `formatResponse()` for signature.
 */
const processFile = (rows, credentialsDomainMap) => {
  const credentialFilter = CredentialFilter(credentialsDomainMap);
  let credentials = new List();

  rows.forEach(row => {
    const data = {};
    let skipRow = false;

    // Parse url first. If it does not exist, skip the row.
    Object.entries(row).forEach(([header, value]) => {
      const lowerHeader = header.toLowerCase();
      if (['url'].includes(lowerHeader)) {
        data.loginUrl = extractLoginUrl(value);
        if (!Validator.url(value)) {
          skipRow = true;
          return;
        }

        const domain = Validator.parseDomain(value);
        if (domain === 'example.com') skipRow = true;
        data.domain = domain;
      }
    });

    if (skipRow || !data.domain) return;

    Object.entries(row).forEach(([header, value]) => {
      const lowerHeader = header.toLowerCase();
      if (['name'].includes(lowerHeader) && !value.includes(data.domain)) {
        data.name = value;
      }
      else if (['grouping'].includes(lowerHeader)) {
        data.groupId = value;
      }
      else if (['password'].includes(lowerHeader)) {
        data.password = value;
      }
      else if (['note', 'extra', 'notes'].includes(lowerHeader)) {
        data.note = decodeURIComponent(escape(value));
      }
      else if (['username/phone number', 'username', 'username2'].includes(lowerHeader)) {
        const loginType = detectLoginInformationType(value);
        data[loginType] = value;
      }
      else if (['email'].includes(lowerHeader)) {
        data.email = value;
      }
    });

    const credential = new Credential(data);
    credentials = credentialFilter(credential, true);
  });

  return credentials;
};

export default {
  isSupportedFile,
  name: 'default',
  processFile,
};
