/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import {
  URL_CLIENTS_REJECT,
  URL_CREDENTIAL,
  URL_CREDENTIALS,
  URL_PREFERENCES,
  URL_USERS,
  DOWNWARD,
  URL_CREDENTIALS_SHARED,
  URL_CREDENTIALS_SHARED_USERS,
  URL_CREDENTIALS_SHARED_INVITATIONS,
  URL_DECLINE_INVITATIONS,
} from 'constants/endpoints';
import { List, Map } from 'immutable';
import {
  CW_DECRYPT_CREDENTIALS,
  CW_DECRYPT_USING_MASTER_KEYPAIR,
  CW_ENCRYPT_CREDENTIALS,
  CW_DECRYPT_WITH_EXTERNAL_KEYS,
  getWorkerPromise,
  CW_DECRYPT_DASHBOARD,
} from '@nettoken/crypto-worker';
import { isProduction } from '@nettoken/env';
import { eventTarget, reloadBrowser, sortObjectKeysAlphabetically } from '@nettoken/helpers';
import { Credential } from '@nettoken/models';
import { SM_DISCONNECT, SM_GET_SOCKET, processSocketEvent } from '@nettoken/socket';

import Validator, {
  USER_AGE_MAX,
  USER_AGE_MIN,
  USER_EMAIL_MAX_LENGTH,
  USER_LOCATION_MAX_LENGTH,
  USER_NAME_MAX_LENGTH,
} from '@nettoken/validator';
import { WEL_SIGNOUT } from '@nettoken/web-extension-link/lib/constants';
import i18n from 'config/i18n';
import { refreshSearch } from 'main/search';
import Routes from 'config/routes';
import { disableGoogleAnalytics, enableGoogleAnalytics } from 'main/analytics/google';
import { saveToLocalDevice } from 'main/device';
import { hideUIError, showUIError } from 'main/error';
import { getError } from 'main/error/reduxState';
import { RXFlagsSet } from 'main/flags/reduxActions';
import { goTo, parseUrlQueryString, stringifyUrlQueryParams } from 'main/router';
import { convertGlobalAccountsIntoObject, getPredefinedAccounts } from 'main/search';
import { showModal, hideModal } from 'main/modal';
import { reduxStorageThrottle } from 'reducers/store';
import { isExtensionConnected } from 'utils/extension';
import { Request, SlackRequest } from 'utils/request';
import { history } from 'reducers/store';
import getUiModelTemplateState from '../ui/reduxState';

import {
  RXUserChangesSave,
  RXUserChangesTemp,
  RXUserLogIn,
  RXUserLogOut,
} from './reduxActions';

import { getUserProfile, getRouterState } from './reduxState';
import { postExtensionMessage } from '../../utils/extension';
import { hideOverlay } from '../overlay';
import { DASHBOARD_SPACES, URL_GROUP } from '../../constants/endpoints';
import { RXDashboardAdd, RXDashboardsCreateBulk, RxEditAccountProccessing } from '../modal/reduxActions';
import { getDashboardInvites, getDashboardList } from '../modal/reduxState';
import { moveCredentialToGroupId } from '../vault/credentials';
import { RXGroupsCreate } from '../vault/groups/reduxActions';

const EXPORT_VERSION = '1.0';

/**
 * @param {string} name
 * @param {string} value
 * @param {List} conditions
 *
 * @returns {integer}
 */
const checkUserPersonalDetailsField = (name, value, conditions) => dispatch => {
  const hasError = dispatch(getError(name));
  let failedTests = 0;
  let errorMessage = '';

  conditions.forEach(condition => {
    const { test } = condition;
    const testSuccess = typeof test === 'function' ? test(value) : true;
    if (!testSuccess) {
      failedTests += 1;

      if (condition.error) errorMessage = condition.error;
    }
  });

  if (failedTests > 0 && errorMessage) {
    dispatch(showUIError(errorMessage, name));
  }
  else if (failedTests === 0 && hasError) {
    dispatch(hideUIError(name));
  }

  return failedTests;
};

/**
 * Create the raw export data object. This is almost export-ready, the only
 * value that needs to be replaced is each credential's `accountId` for
 * the actual `domain` value.
 *
 * @param {object} res Decrypted response from the user profile endpoint.
 *
 * @returns {object}
 * @property {array} accountIds Unique account ids to fetch and replace
 *   in export data.
 * @property {object} data Export data.
 */
const constructUserExportDataObject = res => {
  const uniqueAccountIds = [];
  const data = {
    contact: {},
    createdOn: new Date().toString(),
    groups: [],
    dashboards: [],
    registeredOn: new Date(Number.parseInt(res.createdAt, 10) * 1000).toString(),
    version: EXPORT_VERSION,
  };

  if (res.backup) {
    data.backup = {
      code: res.backup.quotient.join('-'),
      data: res.backup.ciphertext,
    };
  }

  if (res.gdpr) {
    Object.entries(res.gdpr).forEach(([key, value]) => {
      if (value) data.contact[key] = value;
    });
  }

  if (res.referralCode && res.referralCode.code) {
    data.referralCodes = [res.referralCode.code];
  }

  data.groups = res.groups.map(group => {
    const dashboardSpaceId = (!('dashboardSpaceId' in group) || group.dashboardSpaceId === '') ? null : group.dashboardSpaceId;
    const groupObj = {
      name: group.name,
      dashboardSpaceId,
    };
    const credentials = group.credentials.map(acc => {
      const { accountId } = acc;
      const credential = { accountId };
      if (acc.email) credential.email = acc.email;
      if (acc.loginUrl) credential.loginUrl = acc.loginUrl;
      if (acc.name) credential.name = acc.name;
      if (acc.note) credential.memo = acc.note;
      if (acc.password) credential.password = acc.password;
      if (acc.phone) credential.phone = acc.phone;
      if (acc.username) credential.username = acc.username;
      credential.dashboardSpaceId = dashboardSpaceId;
      uniqueAccountIds.push(accountId);
      return credential;
    });
    if (credentials.length) groupObj.credentials = credentials;
    return groupObj;
  });

  return {
    accountIds: uniqueAccountIds,
    data,
  };
};

/**
 * @param {object} profile
 *
 * @returns {object}
 */
const decryptExportData = async profile => {
  const worker = getWorkerPromise('crypto');
  const promises = profile.groups.map(group => {
    const credentials = group.credentials.map(x => x.getState()).toArray();
    return worker({ event: CW_DECRYPT_CREDENTIALS, credentials });
  });
  try {
    const results = await Promise.all(promises);
    results.forEach(({ decrypted }, index) => {
      profile.groups.get(index).credentials = decrypted;
    });
    return profile;
  }
  catch (e) {
    throw e;
  }
};

/**
 * @param {object} data
 *
 * @returns {object}
 */
const encryptExportData = async data => {
  const worker = getWorkerPromise('crypto');
  const credentials = [{ data: JSON.stringify(data) }];
  const { encrypted } = await worker({ event: CW_ENCRYPT_CREDENTIALS, credentials });
  const [encryptedData] = encrypted;
  return encryptedData;
};

/**
 * @returns {Promise}
 */
const endActiveSession = () => {
  const req = new Request(URL_CLIENTS_REJECT);
  return req.authorise().post()
    .catch(e => console.log(e));
};

/**
 * Process the export data and swap every `accountId` reference in credentials
 * with the `domain` value from the referenced global account.
 *
 * @param {array} predefined Array of predefined global accounts.
 * @param {object} data Export data.
 *
 * @returns {object} Extended export data object.
 */
const extendExportDataCredentialsWithPredefinedAccounts = (predefined, data) => {
  const globalAccounts = convertGlobalAccountsIntoObject(predefined, 'id');
  data.groups = data.groups.map(group => {
    if (group.credentials) {
      group.credentials = group.credentials.flatMap(acc => {
        const { accountId, ...credential } = acc;
        if (!globalAccounts[accountId]) return [];
        credential.domain = globalAccounts[accountId].domain;
        return [sortObjectKeysAlphabetically(credential)];
      });
    }
    return sortObjectKeysAlphabetically(group);
  });
  return sortObjectKeysAlphabetically(data);
};

/**
 * @returns {string}
 */
const getExportDataFileName = () => {
  const date = new Date().toLocaleString('en-gb', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });
  const name = i18n.t('views.export.filename', { date });
  return name;
};

/**
 * @param {string} id User id.
 * @param {List} missing
 */
const warnMissingAccounts = (id, missing) => {
  const values = missing.toArray();
  const text = i18n.t('slack.missingAccount.text', { id });
  console.log(text, values);

  if (isProduction && values != null && values.length > 0) {
    const attachments = [{
      title: i18n.t('slack.missingAccount.attachmentTitle'),
      text: values.join('\n'),
    }];
    const channel = i18n.t('slack.missingAccount.channel');
    new SlackRequest({ attachments, text }).to(channel).silent('post');
  }
};

/**
 * Encrypt the account credentials and add them to the user profile.
 *
 * @param {Map} credentialsMap
 *
 * @returns {Map}
 */
export const addUserCredentials = async (credentialsMap, dashboardSpaceId) => {
  const event = CW_ENCRYPT_CREDENTIALS;
  const worker = getWorkerPromise('crypto');
  let promises = Promise.resolve();
  let response = new Map();
  credentialsMap.entrySeq().forEach(([groupId, creds]) => {
    const credentials = creds.toArray().map(x => {
      const keys = new List(['accountId', 'email', 'groupId', 'name', 'note', 'password', 'phone', 'username', 'shared', 'publicKey', 'secretKey']);
      // if (x.get('domain') !== x.get('loginUrl')) keys = keys.push('loginUrl');
      const credsNew = x.filter(keys.toArray());
      return credsNew;
    });
    promises = promises.then(() => worker({ credentials, event })
      .then(data => {
        const req = new Request(URL_CREDENTIALS);
        let newCreds = [];
        if (dashboardSpaceId !== '' && dashboardSpaceId !== null) {
          newCreds = data.encrypted.map(item => {
            item.dashboardSpaceId = dashboardSpaceId;
            return item;
          });
        }
        else {
          newCreds = data.encrypted;
        }
        return req.authorise().post({ credentials: newCreds, groupId });
      })
      .then(res => {
        const newRes = res.credentials.map(cred => cred.id);
        response = response.set(groupId, newRes);
        return Promise.resolve(response);
      })
      .catch(e => console.log(e)));
  });

  try {
    await promises;
    return response;
  }
  catch (e) {
    throw e;
  }
};

export const encryptCredential = async cred => {
  const event = CW_ENCRYPT_CREDENTIALS;
  const worker = getWorkerPromise('crypto');
  let promises = Promise.resolve();
  const keys = ['id', 'accountId', 'email', 'groupId', 'name', 'note', 'password', 'phone', 'username', 'shared', 'publicKey', 'secretKey'];
  let obj = {};

  keys.forEach(key => (
    obj = {
      ...obj,
      [key]: cred[key],
    }
  ));

  const credentials = [obj];
  promises = promises.then(() => worker({ credentials, event })
    .then(data => data)
    .catch(e => Promise.reject(e)));

  try {
    await promises;
    return promises;
  }
  catch (e) {
    throw e;
  }
};

/**
 * @param {object} event
 */
export const changeUserPersonalDetails = event => dispatch => {
  if (!event) return;
  const profile = dispatch(getUserProfile());
  const { name, value } = eventTarget(event);

  let conditions = new List();
  let shouldContinue = false;

  switch (name) {
    case 'age':
      conditions = conditions.push({
        error: i18n.t('error.userPersonalDetails.ageMin', { limit: USER_AGE_MIN }),
        test: value !== '' ? Validator.userAgeMin : null,
      }, {
        error: i18n.t('error.userPersonalDetails.ageMax', { limit: USER_AGE_MAX }),
        test: value !== '' ? Validator.userAgeMax : null,
      });
      shouldContinue = true;
      break;

    case 'email':
      conditions = conditions.push({
        error: i18n.t('error.userPersonalDetails.email', { limit: USER_EMAIL_MAX_LENGTH }),
        test: Validator.userEmailMax,
      });
      break;

    case 'location':
      conditions = conditions.push({
        error: i18n.t('error.userPersonalDetails.location', { limit: USER_LOCATION_MAX_LENGTH }),
        test: value !== '' ? Validator.userLocationMax : null,
      });
      break;

    case 'name':
      conditions = conditions.push({
        error: i18n.t('error.userPersonalDetails.name', { limit: USER_NAME_MAX_LENGTH }),
        test: Validator.userNameMax,
      });
      break;

    default:
      break;
  }

  if (conditions.size) {
    const failedTests = dispatch(checkUserPersonalDetailsField(name, value, conditions));
    if (failedTests > 0 && !shouldContinue) return;
  }

  profile[name] = value;
  dispatch(ephemeralStateChange({ profile }));
};

/**
 * Return an object with user preferences we can plug into the Redux state.
 *
 * @param {object} res Server response (either from GET /gdpr or socket event)
 *
 * @returns {object}
 */
export const constructUserPreferencesObject = res => ({
  age: res.age || '',
  cookies: res.cookies || false,
  device: res.deviceType || false,
  email: res.email || '',
  location: res.location || '',
  name: res.name || '',
  phone: res.phone || '',
  id: res.id || '',
});

/**
 * Permanently delete the user's Nettoken profile. There is
 * no way back after this request is made. All data should be
 * backed up locally prior to this call if the user wishes to
 * come back later and pick up where they left.
 *
 * @returns {Promise}
 */
export const deleteUserAccount = () => dispatch => {
  const req = new Request(URL_USERS);
  return req.authorise().delete()
    .then(() => dispatch(signOutUser({ pushToServer: false })))
    .catch(e => Promise.reject(e));
};

/**
 * @param {List} encrypted
 *
 * @returns {List}
 */
export const decryptUserCredentials = async encrypted => {
  const worker = getWorkerPromise('crypto');
  const event = CW_DECRYPT_CREDENTIALS;
  let credentials = new List();
  let decrypted = new List();
  let pendingAccounts = new List();
  encrypted.forEach(credential => {
    if (typeof credential.getState !== 'undefined') {
      const state = credential.getState();
      if (state.accepted) {
        credentials = credentials.push(state);
      }
      else {
        pendingAccounts = pendingAccounts.push(credential);
      }
    }
    else if ('accepted' in credential) {
      const state = new Credential(credential);
      pendingAccounts = pendingAccounts.push(state);
    }
    else {
      credentials = credentials.push(credential);
    }
  });
  const res = await worker({ credentials: credentials.toArray(), event });
  const arrCredentials = credentials.toArray();
  res.decrypted.forEach(state => {
    const objCred = arrCredentials.find(x => {
      if ('id' in x && x.id === state.id) {
        return true;
      }
      return false;
    });
    if (objCred) {
      state.sharedByUserId = objCred.sharedByUserId;
      state.sharedByUserPublicKey = objCred.sharedByUserPublicKey;
      state.sharedByUserPhone = objCred.sharedByUserPhone;
      state.dashboardSpaceId = objCred.dashboardSpaceId;
      state.sharedByDashboard = objCred.sharedByDashboard;
      state.sharedId = 'sharedId' in objCred ? objCred.sharedId : '';
      state.usersSharedWith = 'usersSharedWith' in objCred ? objCred.usersSharedWith : '';
      delete state.loginUrl;
    }
    const credential = new Credential(state);
    decrypted = decrypted.push(credential);
  });
  // credentials.forEach(state => {
  //   const objCred = arrCredentials.find(x => {
  //     if ('id' in x && x.id === state.id) {
  //       return true;
  //     }
  //     return false;
  //   });
  //   if (objCred) {
  //     state.sharedByUserId = objCred.sharedByUserId;
  //     state.sharedByUserPublicKey = objCred.sharedByUserPublicKey;
  //     state.sharedByUserPhone = objCred.sharedByUserPhone;
  //   }
  //   const credential = new Credential(state);
  //   decrypted = decrypted.push(credential);
  // });

  pendingAccounts.forEach(credential => {
    decrypted = decrypted.push(credential);
  });

  return decrypted;
};

/**
 * Classify supplied login value into one of the Nettoken supported types.
 *
 * @param {string} value Login data.
 *
 * @returns {string}
 */
export const detectLoginInformationType = value => {
  if (Validator.phone(value)) return 'phone';
  if (Validator.email(value)) return 'email';
  return 'username';
};

/**
 * @param {object} credential
 *
 * @returns {Promise}
 */
export const editUserCredential = (
  credential,
  dispatch = null,
  groupLength = 0,
  objState = null,
  prevData = null,
) => new Promise((resolve, reject) => {
  const credentialKeys = [
    'email',
    'groupId',
    'note',
    'name',
    'password',
    'phone',
    'username',
    'shared',
    'publicKey',
    'secretKey',
    'dashboardSpaceId',
  ];
  const worker = getWorkerPromise('crypto');
  const groupId = credential.get('groupId');
  const id = credential.get('id');
  const pendId = credential.get('invitationId');
  const shrd = credential.get('shared');
  const externalAccountpendId = credential.get('externalAccount');
  let newCredId = '';
  const account = {
    email: credential.get('email'),
    groupId: credential.get('groupId') === '' ? null : credential.get('groupId'),
    note: credential.get('note'),
    name: credential.get('name'),
    password: credential.get('password'),
    phone: credential.get('phone'),
    username: credential.get('username') === '' ? credential.get('phone') : credential.get('username'),
    shared: credential.get('shared'),
    publicKey: credential.get('publicKey'),
    secretKey: credential.get('secretKey'),
    dashboardSpaceId: credential.get('dashboardSpaceId'),
  };
  worker({ event: CW_ENCRYPT_CREDENTIALS, credentials: [account] })
    .then(data => {
      if (externalAccountpendId) return null;
      const [encrypted] = data.encrypted;
      encrypted.dashboardSpaceId = account.dashboardSpaceId === '' ? null : account.dashboardSpaceId;
      // if (prevData !== null) {
      //   let newData = {};
      //   credentialKeys.map(key => {
      //     if (account[key] !== prevData[key]) {
      //       newData = {
      //         ...newData,
      //         [key]: account[key] === '' && key === 'groupId' ? null : account[key],
      //       };
      //     }
      //   });
      //   const req = new Request(URL_CREDENTIAL);
      //   return req.setUrlParams({ id: credential.get('id') }).authorise().put(newData);
      // }
      const req = new Request(URL_CREDENTIAL);
      return req.setUrlParams({ id: credential.get('id') }).authorise().put(encrypted);
    })
    .then(res => {
      newCredId = res.credential.id;
      if (account.groupId !== res.credential.groupId && dispatch != null) {
        if (!(res.credential.groupId in objState.groups.data)) {
          const reqUser = new Request(URL_USERS);
          const userData = reqUser.authorise().get();
          userData.then(async userRes => {
            const newGroupData = userRes.profile.groups
              .filter(ur => ur.id === res.credential.groupId);
            await dispatch(RXGroupsCreate(newGroupData[0].id, {
              loading: false,
              dashboardSpaceId: newGroupData[0].dashboardSpaceId,
              meta: { source: 'group', open: false },
              label: newGroupData[0].name,
              id: newGroupData[0].id,
              apps: [res.credential.id],
            }));
            dispatch(refreshSearch());
            dispatch(RxEditAccountProccessing(false));
          });
        }
        dispatch(moveCredentialToGroupId({
          credentialId: res.credential.id,
          groupId: res.credential.groupId,
          pushToServer: false,
          prevGroupId: account.groupId,
          insertIndex: groupLength,
          credential: res.credential,
        }));
      }
      return resolve(res.credential);
    })
    .catch(e => reject(e));
  const req2 = new Request(URL_USERS);
  const users = req2.authorise().get();
  users.then(res => {
    res.profile.groups.forEach(v => {
      v.credentials.forEach(a => {
        if (
          shrd === true &&
          externalAccountpendId === true &&
          (
            account.groupId != a.groupId ||
            account.dashboardSpaceId != a.dashboardSpaceId
          )
        ) {
          if (a.id === id || a.sharedId === pendId) {
            const { sharedId } = a;
            const putParams = {
              sharedId,
              setHomeDashboard: false,
              notify: false,
            };
            if (account.dashboardSpaceId != a.dashboardSpaceId) {
              putParams.dashboardSpaceId = (account.dashboardSpaceId != '' ? account.dashboardSpaceId : null);
              putParams.setHomeDashboard = (account.dashboardSpaceId != '' ? 'false' : 'true');
            }
            if (account.groupId != a.groupId) {
              putParams.groupId = account.groupId;
            }
            const req1 = new Request(DOWNWARD);
            req1.authorise().put(putParams)
              .then(res1 => {
                if (account.groupId !== res1.groupId && dispatch != null) {
                  a.groupId = res1.groupId;
                  a.dashboardSpaceId = res1.dashboardSpaceId;
                  let newGroupLength = groupLength;
                  if (!(res1.groupId in objState.groups.data)) {
                    const reqUser = new Request(URL_USERS);
                    const userData = reqUser.authorise().get();
                    userData.then(async userRes => {
                      const newGroupData = userRes.profile.groups
                        .filter(ur => ur.id === res1.groupId);
                      newGroupLength = newGroupData[0].credentialsOrder.length;
                      await dispatch(RXGroupsCreate(newGroupData[0].id, {
                        loading: false,
                        dashboardSpaceId: newGroupData[0].dashboardSpaceId,
                        meta: { source: 'group', open: false },
                        label: newGroupData[0].name,
                        id: newGroupData[0].id,
                        apps: [res1.credentialId],
                      }));
                      dispatch(refreshSearch());
                      dispatch(RxEditAccountProccessing(false));
                    });
                  }
                  else {
                    const newGroupData = res.profile.groups
                      .filter(ur => ur.id === res1.groupId);
                    newGroupLength = newGroupData[0].credentialsOrder.length;
                  }
                  dispatch(moveCredentialToGroupId({
                    credentialId: res1.credentialId,
                    groupId: res1.groupId,
                    pushToServer: false,
                    prevGroupId: account.groupId,
                    insertIndex: newGroupLength,
                    credential: a,
                  }));
                }
              })
              .catch(e => console.log(e));
          }
        }
      });
    });
    // dispatch(RxEditAccountProccessing(false));
  })
    .catch(e => console.log(e));
});

/**
 * @param {array|object} payloads
 */
export const ephemeralStateChange = payloads => dispatch => {
  if (!Array.isArray(payloads)) payloads = [payloads];
  dispatch(RXUserChangesTemp(payloads));
};

/**
 * Downloads the user's data held on the Nettoken servers
 * and saves them to the local device.
 *
 * @param {boolean} [encrypted=true] Keep the downloaded data encrypted?
 */
export const exportUserData = (encrypted = true) => async dispatch => {
  try {
    const profile = await getUserData();
    const decryptedProfile = await decryptExportData(profile);
    const { accountIds, data: rawExportData } = constructUserExportDataObject(decryptedProfile);
    rawExportData.dashboards = dispatch(getDashboardList());
    const predefined = await getPredefinedAccounts({ ids: accountIds });
    let exportData = extendExportDataCredentialsWithPredefinedAccounts(predefined, rawExportData);
    if (encrypted) exportData = await encryptExportData(exportData);
    saveToLocalDevice(JSON.stringify(exportData, null, 2), getExportDataFileName());
  }
  catch (e) {
    console.log(e);
  }
};

/**
 * @param {string} id User id.
 * @param {array} predefined
 * @param {List} credentials
 *
 * @returns {List}
 */
export const extendCredentialsWithPredefinedAccounts = (id, predefined, credentials) => {
  const globalAccounts = convertGlobalAccountsIntoObject(predefined, 'id');
  let merged = new List();
  let missing = new List();
  credentials.forEach(credential => {
    const accountId = credential.get('accountId');
    const account = globalAccounts[accountId];
    if (!account) {
      if (accountId != '' && accountId != null) {
        missing = missing.push('accountId:'.concat(accountId.toString(), 'account:', JSON.stringify(account), 'credential:', JSON.stringify(credential)));
      }
      return;
    }
    credential.attachGlobalData(account);
    merged = merged.push(credential);
  });
  if (missing.size > 0) {
    warnMissingAccounts(id, missing);
  }
  return merged;
};

const addSharedByCredentials = async (profile, token) => {
  console.log('');
  return profile;
};

/**
 * @param {string} token
 * @param {boolean} [transform=true]
 *
 * @returns {object}
 */
export const getUserData = async (token, transform = true, returnProfile = false) => {
  const req = new Request(URL_USERS);
  let { profile } = await req.authorise(token).get();
  const profile2 = profile;
  profile = await addSharedByCredentials(profile, token);
  if (transform) profile = transformProfileToImmutable(profile);
  if (returnProfile) {
    return {
      encryptedUserCredentials: profile,
      profile: profile2,
    };
  }
  return profile;
};

/**
 * Return the current user's preferences from the server.
 *
 * @param {string} [accessToken]
 *
 * @returns {object} User preferences.
 */
export const getUserPreferences = accessToken => {
  const req = new Request(URL_PREFERENCES);
  return req.authorise(accessToken).get()
    .then(res => Promise.resolve(constructUserPreferencesObject(res.gdpr || {})))
    .catch(e => Promise.reject(e));
};

/**
 * @param {array|object} payloads
 */
export const persistentStateChange = payloads => dispatch => {
  dispatch(ephemeralStateChange(payloads));
  dispatch(RXUserChangesSave());
};

/**
 * Creates a new session in reducer, sets the access token.
 *
 * @param {string} token
 */
export const signInUser = (token, redirect = true) => dispatch => {
  // Stop tracking guests once they log in.
  disableGoogleAnalytics();
  const flags = {};
  dispatch(RXFlagsSet(flags));
  dispatch(RXUserLogIn(token));
  const dashboardList = dispatch(getDashboardList());
  if (Object.keys(dashboardList).length === 0) {
    const dashboardInvites = dispatch(getDashboardInvites());
    const req = new Request(DASHBOARD_SPACES);
    req.authorise().get().then(res => {
      if (dashboardInvites.length > 0) {
        res = [...dashboardInvites, ...res];
      }
      res.map(async (item, index) => {
        const worker = await getWorkerPromise('crypto');
        if (('shared' in item && item.shared) || 'invitationId' in item) {
          delete item.accepted;
          const resDecrypted = await worker({
            event: CW_DECRYPT_DASHBOARD,
            dashboard: [{
              ...item,
              shared: true,
            }],
          });
          item = {
            ...item,
            name: resDecrypted.decrypted[0].name,
          };
        }
        else {
          const objName = await worker({
            event: CW_DECRYPT_USING_MASTER_KEYPAIR,
            message: item.name,
          });
          const name = Object.values(objName)
            .join('')
            .replace('CW_DECRYPT_USING_MASTER_KEYPAIR', '');
          item = {
            ...item,
            name,
          };
        }
        dispatch(RXDashboardAdd(item));
      });
      dispatch(RXDashboardsCreateBulk({
        ...dashboardInvites,
        ...res,
      }));
    })
      .catch(e => Promise.reject(e));
  }
  if (redirect) {
    dispatch(goTo(Routes.DASHBOARD, true));
  }
};

export const logInUser = token => dispatch => {
  disableGoogleAnalytics();
  const flags = {};
  dispatch(RXFlagsSet(flags));
  dispatch(RXUserLogIn(token));
  dispatch(goTo(Routes.SUCCESS_LOGGED_IN, true, true));
};

/**
 * Signs out user out of the current session. By default, we send a request
 * to server as well. If the extension is connected, we let it take care of
 * this. If this method got triggered by an extension, we do not send any
 * requests to avoid infinite loops.
 *
 * @param {object} [args]
 * @param {boolean} [expired=false] Are we signing out user because of the
 *   expired session?
 * @param {boolean} [pushToServer=true]
 */
export const signOutUser = args => async dispatch => {
  const { expired, pushToServer, offline } = Validator.ensureDefaults(args, {
    expired: false,
    pushToServer: true,
  });

  // Start tracking users after they sign out.
  enableGoogleAnalytics();
  if (pushToServer) {
    endActiveSession();

    // if (!isExtensionConnected()) {
    try {
      await processSocketEvent({ event: SM_GET_SOCKET });
      await processSocketEvent({ event: SM_DISCONNECT });
      // window.location.reload();
    }
    catch (e) {
      console.log('nettoken_error_logout', e);
    }
    /* }
    else {
      console.log('nettoken_error_logout_already', isExtensionConnected());
    } */
  }

  if (isExtensionConnected()) {
    try {
      postExtensionMessage({ event: WEL_SIGNOUT });
    }
    catch (e) {
      console.log(e);
    }
  }

  // We must reload browser after this action. This is because memory-only
  // reducers do not clear their state on sign out and rely on browser
  // refresh to do that for them.
  dispatch(RXUserLogOut());

  if (offline) {
    endActiveSession();
    dispatch(showModal('connection-lost'));
    const routerState = dispatch(getRouterState());
    if (routerState && routerState.location.pathname != '/login') {
      history.replace({ location: '/login' });
    }
    return;
  }

  // We need to wait until all data has been cleared.
  setTimeout(() => {
    if (expired) {
      const { origin, pathname } = window.location;
      const queryParams = parseUrlQueryString(window.location.search);
      queryParams.expired = true;
      const search = stringifyUrlQueryParams(queryParams);
      window.location.href = `${origin + pathname}?${search}`;
    }
    else {
      reloadBrowser();
    }
  }, reduxStorageThrottle + 1);
};

/**
 * @returns {Promise}
 */
export const syncUserPersonalDetails = () => dispatch => {
  const profile = dispatch(getUserProfile());
  const params = {
    email: profile.email || '',
    name: profile.name || '',
  };

  const req = new Request(URL_PREFERENCES);
  return req.authorise().put(params)
    .then(() => Promise.resolve())
    .catch(e => Promise.reject(e));
};

/**
 * @param {object} user
 *
 * @returns {object}
 */
export const transformProfileToImmutable = user => {
  const profile = Object.assign({}, user);
  const { groups } = profile;
  const { shared } = profile;
  profile.groups = new List();
  profile.shared = new List();
  // profile.groups = profile.shared.push(shared);
  groups.forEach(_group => {
    const group = Object.assign({}, _group);
    const { credentials } = group;
    group.credentials = new List();
    credentials.forEach(x => {
      const credential = new Credential(x);
      group.credentials = group.credentials.push(credential);
    });
    profile.groups = profile.groups.push(group);
  });
  return profile;
};
